<template>
  <div class="row m-0">
    <div class="col-12 p-0 mt-3">
      <div class="row m-0 justify-content-center">
        <div class="col-auto text-center page_title p-0">
          <div class="page_title" :class="color">
            <span>{{title}}</span>
            <div class="page_title_underline w-50 mt-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section Title',
  props: ['title', 'color']
}
</script>

<style scoped>
.page_title {
  font-size: 14px;
  color: var(--green-color);
  font-family: "quicksand", Sans-serif;
  font-weight: normal;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 8px;
}
.page_title.white {
  color: #fff;
}
.page_title_underline {
  border-bottom: 2px solid var(--gold-color);
  margin-left: 25%;
}
@media (min-width: 992px) {
  .page_title {
    font-size: 41px;
    font-weight: 100;
    line-height: 1.3em;
    letter-spacing: inherit;
  }
}
</style>
